<template>
  <v-container
      id="register"
      class="fill-height justify-center"
      tag="section"
  >
    <Overlay :overlay="globalLoading" :color="'primary'"></Overlay>

    <v-row justify="center" v-show="!globalLoading">
      <v-col cols="12" class="text-center">
        <h1>
          <span class="text-h1">M360API</span> &reg;<br/>
          <span class="text-h2">API Management Platform</span>
        </h1>
      </v-col>
    </v-row>

    <Messages :messages="globalMessages"></Messages>

    <v-row justify="center" v-show="!globalLoading">
      <v-slide-y-transition appear>
        <base-material-card
            v-if="!dialog"
            color="success"
            light
            max-width="100%"
            :width="(pwdHint)? 900 : 400"
            class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Validate Registration
              </h1>
            </div>
          </template>
          <v-row>
            <v-col :cols="(pwdHint) ? 6 : 12">
              <form @submit="checkForm" novalidate="true">
                <v-card-text class="text-center">

                  <v-text-field
                      type="password"
                      class="mb-8"
                      color="secondary"
                      label="Password..."
                      prepend-icon="mdi-lock-outline"
                      v-model="password"
                  />

                  <v-text-field
                      type="password"
                      class="mb-8"
                      color="secondary"
                      label="Confirm Password..."
                      prepend-icon="mdi-lock-outline"
                      v-model="confirmation"
                  />

                  <pages-btn
                      medium
                      color=""
                      depressed
                      class="mr-1 v-btn--text success--text"
                      @click="checkForm"
                  >
                    <v-icon class="mr-1">mdi-lock</v-icon>
                    Submit
                  </pages-btn>
                  <pages-btn
                      v-if="regenerateToken"
                      medium
                      color=""
                      depressed
                      class="mr-1 v-btn--text info--text"
                      @click="dialog=true"
                  >
                    <v-icon class="mr-1">mdi-link</v-icon>
                    Send new Link
                  </pages-btn>
                </v-card-text>
              </form>
            </v-col>
            <v-col cols="6" v-if="pwdHint">
              <v-alert
                  class="pa-1 pl-5"
                  type="info"
                  outlined
                  border="left">
                The Password requires at least one digit.
              </v-alert>
              <v-alert
                  class="pa-1 pl-5"
                  type="info"
                  outlined
                  border="left">
                The Password requires at least one lowercase letter.
              </v-alert>
              <v-alert
                  class="pa-1 pl-5"
                  type="info"
                  outlined
                  border="left">
                The Password requires at least one uppercase letter.
              </v-alert>
              <v-alert
                  class="pa-1 pl-5"
                  type="info"
                  outlined
                  border="left">
                The Password requires at least one special character.
              </v-alert>
              <v-alert
                  class="pa-1 pl-5"
                  type="info"
                  outlined
                  border="left">
                The Password should be between 10 and 32 characters.
              </v-alert>
            </v-col>
          </v-row>
        </base-material-card>
        <base-material-card
            color="success"
            light
            max-width="100%"
            width="400"
            class="px-5 py-3"
            v-else
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Enter your Email
              </h1>
            </div>
          </template>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      color="secondary"
                      label="Email*"
                      prepend-icon="mdi-email"
                      required
                      v-model="email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pages-btn
                medium
                color=""
                depressed
                class="mr-1 v-btn--text success--text"
                @click="requestNewToken"
            >
              <v-icon class="mr-1">mdi-send</v-icon>
              Submit
            </pages-btn>
            <pages-btn
                medium
                color=""
                depressed
                class="mr-1 v-btn--text error--text"
                @click="dialog=false"
            >
              <v-icon class="mr-1">mdi-close</v-icon>
              Close
            </pages-btn>
          </v-card-actions>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>

  </v-container>
</template>

<script>
import globalMixins from "../../mixins/globalMixins";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'ValidateRegister',

  mixins: [globalMixins],

  components: {
    Overlay: () => import('../../components/OverlayLoading'),
    Messages: () => import('../../components/Messages'),
    PagesBtn: () => import('./components/Btn')
  },

  data: () => ({
    email: '',
    password: '',
    confirmation: '',
    pwdHint: false,
    regenerateToken: false,
    dialog: false
  }),

  props: {
    token: {
      type: String
    }
  },

  async created() {
    this.globalLoading = true;

    if (this.isAuthenticated) {
      if (!this.currentUser) {
        // if this page is requested and the user is already logged in but their info is missing, fetch the info then redirect to main dashboard
        return this.getSendData({
          url: '/user',
          method: 'get'
        }).then(response => {
          this.setUserAndACL(response);
          this.$router.push({'name': 'Dashboard'});
        }).catch(error => {
          this.handleErrorResponse(error);
        }).finally(() => {
          this.globalLoading = false;
        });
      } else {
        // if this page is requested and the user is already logged in, redirect to main dashboard
        return this.$router.push({'name': 'Dashboard'});
      }
    }

    this.globalLoading = false;
  },

  methods: {
    ...mapActions('auth', ['setToken', 'setUserAndACL']),

    clearForm() {
      this.email = '';
      this.password = '';
      this.confirmation = '';
    },

    async checkForm(e) {
      this.clearMessages();
      this.pwdHint = false;
      if (!this.password) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide a password.'
        });
        return false;
      }

      let pwdRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\s\*.!@$%\^&(){}\[\]:;<>,.?\/~_+\-=|\\]).{10,32}$/g;
      const pwdMatch = this.password.match(pwdRegex);
      if (!pwdMatch) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Invalid password provided.'
        });
        this.pwdHint = true;
        return false;
      } else {
        this.pwdHint = false;
      }

      if (this.confirmation !== this.password) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'The password and it\s confirmation do not match.'
        });
        return false;
      }

      await this.submit();
      e.preventDefault();
    },

    /* eslint-disable */
    validUserEmail: function (email) {
      var re = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
      return re.test(email);
    },

    fetchUserAndAcl() {
      const self = this;
      self.globalLoading = true;
      return self.getSendData({
        url: '/user',
        method: 'get'
      }).then(response => {
        self.setUserAndACL(response);
      }).catch(error => {
        self.handleErrorResponse(error);
      }).finally(() => {
        self.globalLoading = false;
      });
    },

    /* eslint-enable */
    submit() {
      const self = this;
      this.clearMessages();
      return self.executeRequest({
        url: '/register/validate',
        method: 'post',
        headers: {
          env: 'portal'
        },
        params: {
          "token": this.token,
          "password": this.password,
          "confirmation": this.confirmation
        }
      }).then(response => {
        this.clearForm();
        if (response.token) {
          self.setToken(response.token);
          self.fetchUserAndAcl().then(() => {
            self.$router.push({'name': 'Dashboard', params: {envCode: 'portal'}});
          });
        }
      }).catch(error => {
        self.handleErrorResponse(error);
      });
    },

    requestNewToken() {
      const self = this;
      this.clearMessages();
      if (this.validUserEmail(this.email)) {
        return self.executeRequest({
          url: '/register/token',
          method: 'post',
          headers: {
            env: 'portal'
          },
          params: {
            "email": this.email
          }
        }).then(response => {
          this.pushMessage({
            'type': 'success',
            'title': `Registration Token Sent`,
            'text': "A new Registration Link has been sent to your email address. Please click on that link to validate your registration and proceed."
          });
          this.clearForm();
          this.dialog = false;
        }).catch(error => {
          this.dialog = false;
          self.handleErrorResponse(error);
        });
      } else {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'Please provide a valid email address.'
        });
      }
    },

    handleErrorResponse(error) {
      if (error.errors) {
        for (let code in error.errors) {
          let oneError = error.errors[code];
          if (oneError.code === 160 && oneError.message === "Invalid Token Provided!") {
            this.regenerateToken = true;
          }

          this.pushMessage({
            'type': 'error',
            'title': `Error: ${oneError.code}`,
            'text': oneError.message.toString()
          });

        }
      } else {
        this.pushMessage({
          'type': 'error',
          'title': 'Register Error',
          'text': error.toString()
        });
      }
    }

  }
}
</script>
